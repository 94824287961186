<template>
  <span>
    <span class="mr-1" v-if="+call.datetime_end && +call.datetime_start">{{ $root.printTimeDiff(call.datetime_end - call.datetime_start) }}</span>
    <span style="color: red" v-if="!(+call.datetime_start)">Пропущен</span>
    <AudioPlay v-if="call.record" :src="call.record"/>
  </span>
</template>
<script>

import AudioPlay from "./AudioPlay.vue";

export default {
  name: "CallLabel",
  components: {AudioPlay},
  props: {
    call: {},
  },
}
</script>
<style scoped>
</style>
