<template>
  <ClientLink v-if="item.client && item.client.n_id" :value="item.client"/>
  <div v-else>
    <div style="display: flex">
<!--    <div style="background: #f5f5f5;border-radius: 30px;padding: 2px 5px;display: inline-block;font-size: 12px;margin-right: 5px;display: none">Клиента нет в базе</div>-->
    <div v-if="!+item.client_name" style="white-space: break-spaces">{{ item.client_name }}</div>
    </div>
    <div v-if="item.phone" style="font-size: 14px; white-space: nowrap"><v-icon size="14">mdi-cellphone</v-icon> {{ item.phone }}</div>
    <div v-else style="font-size: 14px;">{{item.contact}}</div>
  </div>
</template>
<script>

import ClientLink from "./ClientLink";
export default {
  name: "LeadContact",
  components: {ClientLink},
  props: {
    item: Object,
  },
}
</script>
