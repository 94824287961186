<template>
  <v-dialog data-app v-model="opened" max-width="600px">
    <v-card>
      <v-card-text>
        <v-row>
          <v-col>
              <v-subheader>Дата оформления</v-subheader>
              <Date v-model="datetimeOrder" @change="getOrders"/>
          </v-col>
          <v-col>
              <v-subheader>Поиск</v-subheader>
              <v-text-field placeholder="Улица" outlined hide-details v-model="search"/>
          </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="orders"
            item-key="id"
            :search="search"
            :loading="loading"
            :footer-props="{'items-per-page-options':[20]}"
            no-data-text="Заказов нет"
            loading-text="Загрузка..."
            mobile-breakpoint="0">
          <template v-slot:item="{item}">
            <tr @click="selectOrder(item)">
              <td>{{ item.n_id }}</td>
              <td>{{ $store.state.cleanTypes[item.type] }}</td>
              <td>{{ item.options }}</td>
              <td>{{ $root.dateToRus(item.datetimecleanObj.date) }}</td>
              <td>{{ item.address.street }}, {{ item.address.house }}</td>
              <td>{{ item.orderStatusString }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Date from "@/components/Date";

export default {
  name: "OrdersModal",
  components: {Date},
  emits: ['on-order-select'],
  data() {
    return {
      opened: false,
      search: '',
      cleaning_date_menu: false,
      datetimeClean: this.$moment().format('YYYY-MM-DD'),
      datetimeOrder: this.$moment().format('YYYY-MM-DD'),
      headers: [
        {value: 'n_id', text: '#'},
        {value: 'type', text: 'Тип'},
        {value: 'options', text: 'Опции'},
        {value: 'datetimecleanObj', text: 'Дата уборки'},
        {value: 'address.street', text: 'Адрес'},
        // {value: '', text: 'Оценка'},
        {value: 'orderStatusString', text: 'Статус'},
      ],
      orders: [],
      loading: false,
    }
  },
  methods: {
    open() {
      this.orders = [];
      this.opened = true;
      this.getOrders();
    },
    selectOrder(order) {
      this.$emit('on-order-select', order);
      this.opened = false;
    },
    getOrders() {
      this.loading = true;
      this.$store.state.server.request('order/get', {
            datetime_from: this.datetimeOrder,
            datetime_to: this.datetimeOrder
          },
          (data) => {
            this.orders = data.response;
            this.loading = false;
          }, () => {
            this.loading = false;
          })
    }
  }
}
</script>

<style scoped>

</style>
