<template>
  <v-dialog data-app v-model="opened" @click:outside="onClose">
    <v-card v-if="loading" class="d-flex justify-center align-center" style="min-height: 300px;">
      <v-progress-circular indeterminate/>
    </v-card>
    <v-card class="lead_card" v-else>
      <v-icon class="btn-close-modal" @click="opened=false;onClose()" size="36">mdi-close</v-icon>
      <v-card-text class="pb-0 px-0">
        <v-row class="lead_halfs">
          <v-col class="pb-2 px-4 action-half">
            <v-card-title class="pa-0">
              <div class="d-flex flex-grow-1" style="justify-content: space-between;align-items: center;">
                <span>
                  Лид №{{ item.id }}
                    <span style="font-size: 14px;color: grey; white-space: nowrap">
                      от {{ $root.dateToRus(item.datetime_createObj.date) }}
                    {{ item.datetime_createObj.time }}
                    </span>
                </span>
                <v-btn @click="createOrder" v-if="!isCleanerLead" small class="ml-2"
                       style="background: #eeeeee !important;">Оформить заказ
                </v-btn>
              </div>
            </v-card-title>
            <div v-if="!isCleanerLead" class="my-2">
              <v-btn small class="mr-auto" :style="+item.checked ? '' : 'background: #eeeeee!important;'"
                     :disabled="Boolean(+item.checked)"
                     @click="markAsChecked">Ответил
              </v-btn>
              <span class="ml-2"
                    v-if="+item.checked && (+item.status)">Ответил: {{ $root.managerName(item.manager_checked) }}</span>
            </div>
            <v-row v-if="+item.call_id" class="mb-3" style="font-size: 12px">
              Звонок
              <CallLabel class="ml-1" :call="item.call"/>
            </v-row>
            <v-alert color="#eee" width="100%" class="pa-2" v-if="item.type.description">
              <div class="alert-inner d-flex align-start">
                <div class="alert-prev">
                  <v-icon class="mr-1" size="16">mdi-information</v-icon>
                </div>
                <div class="alert-content">
                  <div class="alert-title" style="font-size: 14px">{{ item.type.title }}</div>
                  <div class="alert-text" v-html="item.type.description" style="font-size: 12px"/>
                </div>
              </div>
            </v-alert>
            <template v-if="item.order && item.order.n_id">
              <v-subheader>Заказ</v-subheader>
              <OrderLink :value="item.order" :full-info="true" class="mb-2" style="font-weight: normal"/>
            </template>
            <v-alert v-if="isCleanerLead" color="#eee" width="100%" class="pa-2">
              <span style="font-size: 14px">{{ item.message }}</span>
            </v-alert>
            <template v-if="isCleanerLead">
              <v-subheader>Сегодняшние заказы</v-subheader>
              <template v-if="todayOrders.length">
                <OrderCard v-for="today_order in todayOrders" :key="today_order.n_id"
                           :sub-action="{item: today_order, collapse: today_order.collapse}"/>
              </template>
              <template v-else>
                У клинера на сегодня нет заказов
              </template>
              <v-subheader>Действия</v-subheader>
              <v-row v-if="item.type.name === 'cleaner_penalty'">
                <v-col>
                  <v-subheader>Сумма штрафа</v-subheader>
                  <v-text-field style="width: 200px;" v-model="item.type_data" outlined/>
                </v-col>
              </v-row>
              <v-row v-if="item.type.name === 'cleaner_penalty'">
                <v-col class="pl-0 py-0">
                  <v-btn width="100%" :style="+item.checked ? '' : 'background: #f5f5f5 !important;'"
                         :disabled="+item.status === 2"
                         @click="markAsChecked">
                    Не начислять штраф
                  </v-btn>
                </v-col>
                <v-col class="pl-0 py-0">
                  <v-btn width="100%" :style="+item.checked ? '' : 'background: #f5f5f5 !important;'"
                         :disabled="+item.status === 2" :loading="addPenaltyLoading"
                         @click="addPenalty">Подтвердить штраф
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col class="pl-0 py-0">
                  <v-btn width="100%" :style="+item.checked ? '' : 'background: #f5f5f5 !important;'"
                         :disabled="+item.status === 2"
                         @click="markAsChecked">Обработан
                  </v-btn>
                </v-col>
                <v-col class="pl-0 py-0">
                  <v-btn v-if="item.folder!=='cleaners_hr'" @click="openToHrModal" width="100%"
                         style="background: #f5f5f5 !important;"
                         :disabled="+item.status === 2">Перенаправить hr-у
                  </v-btn>
                </v-col>
              </v-row>
            </template>
            <template v-else>
              <div style="display:flex;flex-direction: column;gap: 10px;width: fit-content">
                <v-subheader class="pb-0 mb-0">Сценарии продаж</v-subheader>
                <div class="d-flex align-end">
                  <v-tooltip top :disabled="!item.buttons.general_order.tip">
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <v-btn @click="sendByButton('general_order')" small width="150"
                               :disabled="item.buttons.general_order.disabled"
                               style="background: rgb(46, 184, 92) !important; color: #FFF !important;">Генеральная
                          уборка
                        </v-btn>
                      </div>
                    </template>
                    <span v-html="item.buttons.general_order.tip"/>
                  </v-tooltip>
                  <div class="ml-2" v-if="item.buttons.general_order.text">
                    {{ item.buttons.general_order.text }}
                  </div>
                </div>
                <div class="d-flex align-end">
                  <v-tooltip top :disabled="!item.buttons.services.tip">
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <v-btn @click="sendByButton('services')"
                               :disabled="item.buttons.services.disabled" small width="150"
                               :style="item.buttons.services.disabled ? '' :'background: #eee !important;'">Доп. услуги
                        </v-btn>
                      </div>
                    </template>
                    <span v-html="item.buttons.services.tip"/>
                  </v-tooltip>
                  <div class="ml-2" v-if="item.buttons.services.text">
                    {{ item.buttons.services.text }}
                  </div>
                </div>
              </div>
              <v-row class="mt-8">
                <v-tooltip top :disabled="!item.buttons.mobile_order.lastAction.date && !item.buttons.mobile_order.tip">
                  <template v-slot:activator="{ on, attrs }">
                    <v-col class="pa-0 d-flex flex-column align-center" v-bind="attrs" v-on="on">
                      <v-btn @click="sendByButton('mobile_order')"
                             :loading="buttonLoading==='mobile_order'"
                             :disabled="item.buttons.mobile_order.disabled" small width="110"
                             style="background: #eeeeee !important;">
                        <v-icon class="mr-2" size="16">mdi-apple</v-icon>
                        <v-icon>mdi-android</v-icon>
                      </v-btn>
                      <v-progress-circular
                          :value="(item.buttons.mobile_order.success / item.buttons.mobile_order.total) * 100"
                          :rotate="-90" :size="60"
                          :color="item.buttons.mobile_order.success > 0 ? 'green': 'red'"
                          class="mt-2">{{ item.buttons.mobile_order.success }} / {{ item.buttons.mobile_order.total }}
                      </v-progress-circular>
                      <v-row>3 000 {{ $store.state.user.city.country.currency }}</v-row>
                    </v-col>
                  </template>
                  <span v-html="item.buttons.mobile_order.tip"/>
                </v-tooltip>
                <v-tooltip top :disabled="!item.buttons.friend.lastAction.date && !item.buttons.friend.tip">
                  <template v-slot:activator="{ on, attrs }">
                    <v-col class="pa-0 d-flex flex-column align-center" v-bind="attrs" v-on="on">
                      <v-btn @click="sendByButton('friend')" small width="110"
                             :disabled="item.buttons.friend.disabled"
                             style="background: #eeeeee !important;">Приведи друга
                      </v-btn>
                      <v-progress-circular :value="(item.buttons.friend.success / item.buttons.friend.total) * 100"
                                           :rotate="-90" :size="60"
                                           :color="item.buttons.friend.success > 0 ? 'green': 'red'"
                                           class="mt-2">{{ item.buttons.friend.success }} / {{
                          item.buttons.friend.total
                        }}
                      </v-progress-circular>
                      <v-row>3 000 {{ $store.state.user.city.country.currency }}</v-row>
                    </v-col>
                  </template>
                  <span v-html="item.buttons.friend.tip"/>
                </v-tooltip>
                <v-tooltip top :disabled="!item.buttons.housemap.lastAction.date && !item.buttons.housemap.tip">
                  <template v-slot:activator="{ on, attrs }">
                    <v-col class="pa-0 d-flex flex-column align-center" v-bind="attrs" v-on="on">
                      <v-btn @click="sendByButton('housemap')" :disabled="item.buttons.housemap.disabled" small
                             width="110"
                             style="background: #eeeeee !important;">Карта дома
                      </v-btn>
                      <v-progress-circular :value="(item.buttons.housemap.success / item.buttons.housemap.total) * 100"
                                           :rotate="-90" :size="60"
                                           :color="item.buttons.housemap.success > 0 ? 'green': 'red'"
                                           class="mt-2">{{ item.buttons.housemap.success }} /
                        {{ item.buttons.housemap.total }}
                      </v-progress-circular>
                      <v-row>3 000 {{ $store.state.user.city.country.currency }}</v-row>
                    </v-col>
                  </template>
                  <span v-html="item.buttons.housemap.tip"/>
                </v-tooltip>
              </v-row>
              <v-row v-if="item.status!=2" class="align-end">
                <v-select v-if="activeManagers && activeManagers.length"
                          v-model="item.assignedManager"
                          @change="update()"
                          class="mr-4"
                          outlined
                          placeholder="Линия" :items="activeManagers.map(id=>({id, login: $root.managerName(id)}))" item-value="id" item-text="login"/>
                <v-btn @click="transferToOKK"
                       :loading="to_okk_loading"
                       class="mt-2"
                       style="background: #eeeeee !important;">Передать в ОКК</v-btn>
              </v-row>
              <v-row class="mt-4 row-d-block">
                <v-col class="pa-0">
                  <v-subheader>Перевести в</v-subheader>
                  <v-row class="mb-4">
                    <v-col class="pa-0">
                      <v-btn class="mr-2" :disabled="item.status==2 && item.result!==1"
                             :style="item.result===1?'':'background: #f5f5f5 !important;'"
                             width="100%"
                             @click="item.result=1;item.statusToSet=2;$forceUpdate()">Заказ
                      </v-btn>
                    </v-col>
                    <v-col class="pa-0 px-2">
                      <v-btn class="mr-2" :disabled="item.status==2 && item.result!==2"
                             :style="item.result===2?'':'background: #f5f5f5 !important;'"
                             @click="item.result= 2; item.statusToSet= 1;$forceUpdate()" width="100%">
                        Ожидание
                      </v-btn>
                    </v-col>
                    <v-col class="pa-0">
                      <v-btn class="mr-2" :disabled="item.status==2 && item.result!==3"
                             :style="item.result===3?'':'background: #f5f5f5 !important;'"
                             width="100%"
                             @click="item.result=3;item.statusToSet=2;$forceUpdate()">Закрыть без заказа
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <div v-if="item.result===1">
                  <OrderLink v-if="item.order && item.order.n_id" :value="item.order"/>
                  <v-btn v-else small @click="addOrder" style="cursor: pointer; background: #eeeeee !important;">Укажите
                    какой заказ привязать к лиду
                  </v-btn>
                  <div class="order-error"
                       :style="`visibility: ${orderError ? 'visible' : 'hidden'}`">Необходимо выбрать заказ
                  </div>
                </div>
                <template v-if="item.result===2 || (item.datetime_waitObj && item.datetime_waitObj.date)">
                  <div class="mt-2" v-if="$root.managerName(item.manager_wait)">В ожидание перевел:
                    <b>{{ $root.managerName(item.manager_wait) }}</b></div>
                  <div class="mb-2">
                    <v-subheader class="d-flex align-center mb-1">
                      Дата след контакта
                      <v-btn @click="item.result = null; $forceUpdate(); item.datetime_waitObj = {}"
                             x-small class="ml-auto" style="background: #eee !important;">Отменить ожидание
                      </v-btn>
                    </v-subheader>
                    <div style="display: flex;flex-direction: row;gap: 10px">
                      <Date placeholder="Дата" v-model="item.datetime_waitObj.date"/>
                      <v-text-field v-model="item.datetime_waitObj.time" placeholder="Время"
                                    v-mask="'##:##'" outlined type="time"/>
                    </div>
                  </div>
                </template>
                <div v-if="item.result===3">
                  <v-select v-model="item.reject_reason" :items="reject_reasons" outlined
                            @change="reason_menu=false; item.transfer_to = ''"
                            :placeholder="'Выберите причину'"
                            :disabled="+item.status === 2"/>
                  <v-select v-if="item.reject_reason === 'Передача в другой город'"
                            v-model="item.transfer_to"
                            class="mt-2"
                            placeholder="Выберите город"
                            outlined :items="$store.state.user.allCities" item-value="id" item-text="city"/>
                  <v-alert v-if="item.reject_reason === 'Передача в другой город'" type="info" class="mt-2">
                    Добавьте в коммент всю инфу для другого города, тк туда отправится только контакт клиента
                  </v-alert>
                </div>
              </v-row>
              <template v-if="item.datetime_closedObj && item.datetime_closedObj.date">
                <v-row>Обработан:
                  {{ $root.dateToRus(item.datetime_closedObj.date) }} {{ item.datetime_closedObj.time }}
                </v-row>
                <v-row v-if="$root.managerName(item.manager_closed)">
                  Закрыл: <b>{{ $root.managerName(item.manager_closed) }}</b>
                </v-row>
              </template>
            </template>
            <v-row class="row-d-block mt-4">
              <v-subheader>Коммент по лиду для менеджеров</v-subheader>
              <v-textarea v-model="item.comment" placeholder="Коммент" outlined
                          @input="comment_error = false"
                          no-resize/>
            </v-row>
            <v-btn class="mt-4" :disabled="+item.status===2" @click="update" :loading="btn_loading">Сохранить</v-btn>
          </v-col>
          <v-col class="px-0" style="border-left: 1px solid #ddd;border-top: 1px solid #ddd;">
            <div style="border-bottom: 1px solid #ddd;text-align: center;" class="pl-2 pb-1">
              <CleanerLink v-if="isCleanerLead" :value="item.cleaner" class="mx-auto "
                           style="font-size: 12px; font-weight: normal; line-height: 150%; width: fit-content"/>
              <LeadContact v-else class="mx-auto my-0"
                           style="font-size: 12px; font-weight: normal; line-height: 150%; width: fit-content"
                           :item="item"/>
            </div>
            <ChatContainer class="px-2" :item="item" ref="chat_container" :show-textarea="true"
                           message-request-method="leads" :template-messages="templateMessages"/>
          </v-col>
          <!--            <ClientActions :item="item"/>-->
        </v-row>
      </v-card-text>
    </v-card>
    <OrdersModal ref="orders_modal" @on-order-select="onOrderSelect"/>
    <Prompt ref="to_hr_modal" title="Перенаправить лид к HR-у?" @success="toHr" :loading="hr_modal_success_loading"/>
  </v-dialog>
</template>

<script>
import OrdersModal from "@/views/leads/OrdersModal";
import InfoTip from "@/components/InfoTip";
import OrderLink from "../../components/OrderLink";
import ClientLink from "../../components/ClientLink";
import Date from "../../components/Date";
import ClientActions from "../../containers/Modals/ClientActions";
import LeadSourceIcon from "../../components/LeadSourceIcon";
import LeadContact from "../../components/LeadContact";
import ChatContainer from "@/views/leads/components/ChatContainer";
import CallLabel from "../../components/CallLabel.vue";
import globalMixins from "@/mixins/globalMixins";
import CleanerLink from "@/components/CleanerLink";
import OrderCard from "@/components/OrderCard";
import Prompt from "@/containers/Modals/Prompt";

export default {
  name: "LeadModal",
  components: {
    Prompt,
    OrderCard,
    CleanerLink,
    CallLabel,
    ChatContainer,
    LeadContact,
    LeadSourceIcon,
    ClientActions,
    Date,
    ClientLink,
    OrderLink,
    InfoTip,
    OrdersModal
  },
  mixins: [globalMixins],
  emits: ['on-lead-update'],
  data() {
    return {
      opened: false,
      loading: true,
      result: null,
      buttonLoading: '',
      item: {
        client: {},
        cleaner: {},
        buttons: {},
        datetime_waitObj: {date: '', time: ''}
      },
      initialItem: {
        client: {},
        datetime_waitObj: {date: '', time: ''}
      },
      wait_menu: false,
      btn_loading: false,
      comment_error: false,
      reason_menu: false,
      reject_reasons: [],
      call: {},
      request: 0,
      orderError: false,
      lastActionDatetime: 0,
      hr_modal_success_loading: false,
      templateMessages: [],
      addPenaltyLoading: false,
      to_okk_loading: false,
      activeManagers: [],
    }
  },
  computed: {
    isCleanerLead() {
      return !this.item.isClientLead;
    },
    todayOrders() {
      return this.item.todayOrders.map(order => {
        return {
          ...order,
          collapse: !order.isActive
        }
      });
    }
  },
  mounted() {
    if (this.$route.query.lead_id) this.open(this.$route.query.lead_id);
  },
  methods: {
    createOrder() {
      let phone = this.item.client && this.item.client.phone ? this.item.client.phone : this.item.contact;
      let routeData = this.$router.resolve({path: '/create/' + phone});
      window.open(routeData.href, 'blank');
    },
    open(lead_id) {
      this.item = {
        client: {},
        datetime_waitObj: {date: '', time: ''}
      };
      this.initialItem = {
        client: {},
        datetime_waitObj: {date: '', time: ''}
      };
      this.comment_error = false;
      this.opened = true;
      this.loading = true;
      this.$router.push({query: {...this.$route.query, lead_id: lead_id}}).catch(() => {
      });
      this.getLead(lead_id, true);
    },
    getLead(id, firstTime = true) {
      if (!this.opened || +this.$route.query.lead_id !== +id) return;
      if (firstTime) {
        this.item = {};
        this.loading = true;
      }
      this.request++;
      let request = this.request;
      this.$store.state.server.request(`leads/get/${id}`, {}, (data) => {
        if (request === this.request && firstTime) {
          this.activeManagers = data.activeManagers;
          this.item = data.response;
          this.templateMessages = data.templateMessages;
          let actions = {};
          if (this.item.isClientLead) actions = this.item.client.history.actions;
          else actions = this.item.cleaner.history.actions;
          let dates = Object.keys(actions);
          if (dates.length) {
            let lastDate = dates[0];
            this.lastActionDatetime = +actions[lastDate][0].date;
          }
          this.initialItem = data.response;
          if (!this.item.client) this.item.client = {};
          if (!this.initialItem.client) this.initialItem.client = {};
          this.reject_reasons = data.reject_reasons;
          if (!this.item.datetime_waitObj || !this.item.datetime_waitObj.date) {
            this.item.datetime_waitObj = {date: '', time: ''};
          }
          if (!this.initialItem.datetime_waitObj || !this.initialItem.datetime_waitObj.date) {
            this.initialItem.datetime_waitObj = {date: '', time: ''};
          }
          if (+this.item.status) {
            if (this.item.reject_reason) this.item.result = 3;
            else if (+this.item.status === 1) this.item.result = 2;
            else this.item.result = 1;
          }
          if (+this.item.call_id) this.getInitialCall();
          this.loading = false;
        } else if (!firstTime) {
          let actions = {};
          if (data.response.client.history) {
            actions = this.clone(data.response.client.history.actions);
            // this.item.client.history.actions = this.clone(actions);
          } else if (data.response.cleaner.history) {
            actions = this.clone(data.response.cleaner.history.actions);
            // this.item.cleaner.history.actions = this.clone(actions);
          }
          this.item.buttons = data.response.buttons;
          let newActions = {};
          Object.entries(actions).forEach(([data, _actions]) => {
            _actions.forEach(action => {
              if (+action.date > this.lastActionDatetime) {
                this.lastActionDatetime = +action.date;
                if (!newActions[data]) newActions[data] = [action];
                else newActions[data].push(action);
              }
            })
          })
          let newActionsDates = Object.keys(newActions);
          if (newActionsDates.length) {
            newActionsDates.forEach(date => {
              if (data.response.client.history) {
                if (!this.item.client.history.actions[date]) this.item.client.history.actions[date] = [];
                this.item.client.history.actions[date].unshift(...newActions[date]);
              } else if (data.response.cleaner.history) {
                if (!this.item.cleaner.history.actions[date]) this.item.cleaner.history.actions[date] = [];
                this.item.cleaner.history.actions[date].unshift(...newActions[date]);
              }
            });
            if (this.$refs.chat_container.scrollOffset - this.$refs.chat_container.initialScrollOffset < 200) {
              setTimeout(() => {
                this.$refs.chat_container?.scrollToEnd(true);
              }, 200)
            }
          }
        }
        setTimeout(() => {
          this.getLead(id, false)
        }, 5000)
        if (firstTime)
          setTimeout(() => {
            this.$refs.chat_container?.scrollToEnd(firstTime);
          }, 500);
      }, () => {
        setTimeout(() => {
          if (this.opened) this.getLead(id, false)
        }, 5000)
        this.loading = false;
      })
    },
    sendByButton(button) {
      if (!confirm('Отправить сообщения клиенту?')) return;
      if (this.buttonLoading) return;
      this.buttonLoading = button;
      this.$store.state.server.request('leads/sendByButton/' + this.item.id, {button},
          (data) => {
            this.buttonLoading = '';
          }, (data) => {
            this.error = data.error || 'Не получилось отправить';
            this.buttonLoading = '';
          }
      )
    },
    addOrder() {
      this.$refs.orders_modal.open();
    },
    findByPhone(value) {
      this.$store.state.server.request('client/get', {phone: value}, (data) => {
        if (data.response.length === 1) {
          this.item.client = data.response[0];
        }
      })
    },
    onOrderSelect(order) {
      this.item.order = order;
      if (order.n_id) this.orderError = false;
    },
    update(callback) {
      // if (!this.item.comment && !+this.item.order.n_id) {
      //   this.comment_error = true;
      //   return;
      // }
      if (this.item.statusToSet) this.item.status = this.item.statusToSet;
      if (+this.item.result === 1 && !+this.item.order.n_id) {
        this.orderError = true;
        return;
      }
      let history = this.item.client.history;
      delete this.item.client.history;
      this.btn_loading = true;
      this.$store.state.server.request(`leads/update/${this.item.id}`, this.item, () => {
        this.btn_loading = false;
        this.opened = false;
        this.onClose();
        if (typeof callback === 'function') callback();
        else this.$emit('on-lead-update');
      }, () => {
        this.btn_loading = false;
      });
      this.item.client.history = history;
    },
    clearDatetimeWait() {
      this.item.datetime_waitObj.date = '';
      this.item.datetime_waitObj.time = '';
    },
    getInitialCall() {
      this.$store.state.server.request(`calls/get/${this.item.call_id}`, {}, (data) => {
        this.call = data.response;
      })
    },
    /* onButtonsClick(type) {
         this.$store.state.server.request(`client/addAction/${this.item.client.id}`,
             {button_type: type, user: this.$store.state.user.n_id}, () => {
           this.getLead(this.item.id, false);
         })
     },*/
    onClose() {
      let query = this.clone(this.$route.query);
      delete query.lead_id;
      this.$router.push({query});
      clearTimeout();
    },
    markAsChecked() {
      this.update(() => {
        this.$store.state.server.request(`leads/check/${this.item.id}`, {}, () => {
          this.$root.notify(this.isCleanerLead ? 'Лид обработан' : 'Лид помечен, как прочитанный', 'info');
          this.$emit('on-lead-update');
        })
      })
    },
    openToHrModal() {
      this.$refs.to_hr_modal.open();
    },
    toHr() {
      this.hr_modal_success_loading = true;
      this.$store.state.server.request(`leads/toHr/${this.item.id}`, {}, () => {
        this.hr_modal_success_loading = false;
        this.$root.notify('Лид перенаправлен HR-у', 'info');
        this.$refs.to_hr_modal.close();
        this.update();
        this.opened = false;
        this.onClose();
      }, (data) => {
        this.hr_modal_success_loading = false;
        this.$root.notify(data.error, 'error');
      })
    },
    addPenalty() {
      this.addPenaltyLoading = true;
      this.$store.state.server.request(`leads/addPenalty/${this.item.id}`, {
        type_data: this.item.type_data,
        comment: this.item.comment
      }, () => {
        this.addPenaltyLoading = false;
        this.$root.notify('Штраф начислен', 'info');
        this.opened = false;
        this.onClose();
      }, () => {
        this.addPenaltyLoading = false;
      })
    },
    transferToOKK() {
      this.to_okk_loading = true;
      this.$store.state.server.request(`leads/toOKK/${this.item.id}`, {}, ()=>{
        this.to_okk_loading = false;
        this.$root.notify('Лид передан в ОКК', 'info');
        this.opened = false;
      }, ()=>{
        this.to_okk_loading = false;
      })
    }
  }
}
</script>

<style scoped lang="scss">
.button-stat {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid #f1f1f1;
}

.order-error {
  color: red;
  font-size: 12px;
}

.lead_halfs {
  .action-half {
    overflow: auto;
    height: 88vh;
  }
}

@media screen and (max-width: 575px) {
  .lead_halfs {
    display: block;

    .action-half {
      height: 100%;
    }
  }
  .lead_card {
    height: auto !important;
  }
}
</style>
